:root {
  --cream: #ffefe7;
  --pale-pink: #ffd2ce;
  --medium-pink: #fca9ae;
  --dark-pink: #e4626b;
  --yellow: #f2ca61;
  --black: #140200;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main { 
  flex-grow: 1;
}

body {
  background-color: var(--cream);
  background-image: url("../public/images/wave.svg"),
    url("../public/images/wave-contact.svg");
  background-position: top, bottom;
  background-repeat: no-repeat;
  background-size: auto 725px, auto 680px;
  color: var(--black);
  min-height: 100vh;
}

body.no-background {
  background-image: none;
}

a {
  color: var(--black);
  text-decoration: none;
}

a:hover {
  color: var(--dark-pink);
}

a:visited, a:active {
  color: var(--black);
}

/* ========== TYPOGRAPHY ========== */

h1 {
  font-family: "DM Serif Display", serif;
  font-size: 2.25rem;
}

h4,
a {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

h2 {
  font-family: "DM Serif Display", serif;
  font-size: 2.25rem;
}

h3 {
  font-family: "DM Serif Display", serif;
  font-size: 1.75rem;
}

p {
  font-family: "DM Sans", sans-serif;
}

/* ========== NAVIGATION ========== */

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 4em 2em;
}

.nav-title {
  text-transform: lowercase;
}

.social-links {
  display: flex;
  gap: 1em;
  font-size: 1.5rem;
  order: 2;
}

.social-links a:hover {
  color: var(--dark-pink);
}
.nav-links {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
  font-size: 1.175rem;
}

.nav-links a:hover {
  color: var(--dark-pink);
}

.menu-icon .menu {
  display: none;
}

.closed {
  display: none;
}

@media only screen and (max-width: 992px) {

  nav {
    margin: 1em 1em;
  }

  nav h1 {
    font-size: 2rem;
  }

  .social-links {
    display: none;
  }
  
  .slider {
    position: fixed;
    min-width: 100%;
    height: 100vh;
    background-color: var(--pale-pink);
    right: -100%;
    top: 0;
    transition: 1s ease;
    z-index: 9;
  }
  
  .slider.active {
    top: 0;
    right: 0;
    transition: 1s ease;
  }
  
  .nav-links {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .nav-links a {
    font-size: 1.5rem;
    margin-bottom: 1.5em;
  }
  
  .menu-icon .menu {
    display: block;
    color: var(--black);
    cursor: pointer;
    font-size: 1.5em;
  }
  
  .closed {
    display: flex;
    justify-content: flex-end;
    color: var(--black);
    cursor: pointer;
    font-size: 1.5em;
    width: 100%;
    margin-top: 1em;
    margin-right: 1em;
  }
}

/* ========== HEADER ========== */

header {
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  align-items: center;
  margin: 0 2em;
}

.header-section {
  visibility: hidden;
}

.header-img {
  width: 285px;
  height: 300px;
  margin: 0 auto;
}

.header-emphasis {
  font-family: "DM Serif Display", serif;
  font-style: italic;
  width: 90%;
  font-size: 1.125rem;
  margin-top: 1em;
}

.header-copy {
  margin: 1em 0;
  width: 90%;
  font-size: 1.125rem;
}

.header-btn {
  color: white;
  font-family: Oswald;
  text-transform: uppercase;
  font-size: 1.125rem;
  background-color: var(--dark-pink);
  border: none;
  padding: 0.75em 2.5em;
  cursor: pointer;
}

.header-btn:hover,
.header-btn:focus {
  background-color: var(--medium-pink);
  color: var(--black);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.sparkle-container {
  position:relative;
  order: -1;
}
.sparkle {
  display: block;
  position: absolute;
  -webkit-animation: bummer 2s;
  animation: bummer 2s;
  -webkit-transform: scale(0,0); 
  transform: scale(0,0);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes bummer {
  100% {
      -webkit-transform: scale(1,1); 
  }
}

@keyframes bummer {
  100% {
      transform: scale(1,1); 
  }
}

.sparkle.one {
  width: 35px;
  left: 50px;
  top: 0px;
}

.sparkle.two {
  width: 35px;
  top: 280px;
  left: 20px;
}

.sparkle.three {
  width: 40px;
  top: 220px;
  left: 260px;
}


@media only screen and (min-width: 768px) {
  header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em 0;
    justify-items: center;
    align-items: center;
    max-width: 700px;
    margin: 0 auto;
  }
  .header-img {
    width: 332px;
    height: 350px;
  }

  .header-title {
    font-size: 3.25rem;
  }

  .header-copy,
  .header-emphasis {
    width: 85%;
  }
  .header-section {
    order: -1;
  }

  .sparkle.two {
    width: 35px;
    top: 300px;
    left: 30px;
  }
  
  
}

@media only screen and (min-width: 992px) {
 header {
  max-width: 900px;
 }
}

/* ========== FOOTER ========== */

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--black);
  color: white;
  padding: 0.5em 1em;

}

.footer-social-links a {
  color: white;
  font-size: 1.5rem;
  margin-left: 1em;
}

.footer-social-links a:hover,
a:focus {
  color: var(--pale-pink);
}

@media only screen and (min-width: 768px) {
  footer {
    padding: 0.5em 4em;
    margin-top: 2em;
  }
}

/* ========== SKILLS ========== */

.skills {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  justify-content: space-around;
  margin: 3em 2em;
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skill-icon {
  font-size: 3.75rem;
  color: var(--dark-pink);
}

.skill p {
  font-size: 1.125rem;
  margin-top: 1em;
  font-family: oswald;
}

@media only screen and (min-width: 768px) {
  .skill-icon {
    font-size: 4.5rem;
  }
  .skill p {
    font-size: 1.175rem;
  }

  .skills {
    max-width: 700px;
    margin: 6em auto 4em;
  }
}

@media only screen and (min-width: 992px) {
  .skills {
   max-width: 900px;
  }
 }

/* ========== PROJECTS ========== */

#projects{
  margin: 0 auto;
}

.projects-title {
  text-align: center;
  margin-bottom: 1em;
}

.project-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  gap: 1em;
  margin-bottom: 2em;
}

.project-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  margin-bottom: 2em;
  flex-grow: 1;
  margin-left: 2rem;
}

.project-copy {
  width: 90%;
}

.project-tech {
  display: flex;
  gap: 1em;
}

.project-tech p {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  border: 2px solid;
  border-image: linear-gradient(
      150deg,
      var(--dark-pink),
      var(--medium-pink),
      var(--pale-pink)
    )
    1;
  padding: 0.5em 1.25em;
  margin: 0;
}

.project-links {
  display: flex;
  gap: 1em;
}

.project-links a {
  padding: 0.5em 1.25em;
  background-color: var(--dark-pink);
  border: 2px solid var(--dark-pink);
  color: white;
}
.project-links a:hover {
  color: var(--black);
  background-color: var(--medium-pink);
  border: 2px solid var(--medium-pink);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.project-img-container {
  margin: 0 auto;
  position: relative;
  flex-grow: 0;
  flex-basis: 0;
}

.project-img{
  width: 300px;
  height: 300px;
  border: 10px solid white;
}

.project-img-container:after{
  content: "";
  width: 300px;
  height: 300px;
  background-color: black;
  display: block;
  position: absolute;
  top: 10px;
  transform: rotate(7deg);
  z-index: -1;
}

.project-img-container.mid:after {
  background-color: var(--medium-pink);
}
.project-img-container.yellow:after {
  background-color: var(--yellow);
}
.project-img-container.pale:after {
  background-color: var(--pale-pink);
}

@media only screen and (min-width: 768px) {
  .projects {
   max-width: 700px;
   margin: 0 auto;
  }
 }

@media only screen and (min-width: 992px) {
  .projects {
    max-width: 900px;
    margin: 0 auto;
   }
  .project-card {
    flex-direction: row;
    margin-bottom: 4em;
  }

  .project-details.swap{
    order: -1;
    margin-right: 2rem;
    margin-left: 0;
    align-items: flex-end;
  }

  .project-details.swap .project-copy{
    text-align: right;
  }

  .project-tech p {
    padding: 0.75em 2em;
  }

  .project-links a {
    padding: 0.75em 2em;
  }
}


/* ========== FEATURED ========== */

.featured {
  background-color: var(--pale-pink);
  text-align: center;
  margin: 2em 0;
  padding: 2em 0;
  width: 100%;
}

.featured h4 {
  margin-bottom: -10px;
  font-weight: 400;
}

.featured-video {
  margin: 1em 0;
  width: 300px;
  height: 169px;
}

@media only screen and (min-width: 500px) {
   .featured-video {
    width: 400px;
    height: 225px;
  }
}

@media only screen and (min-width: 768px) {
  
  .featured-video {
    width: 400px;
    height: 225px;
  }
}

@media only screen and (min-width: 992px) {
  .featured {
    background-image: url("https://images.unsplash.com/photo-1554415707-6e8cfc93fe23?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    width: 100%;
  }

  .featured-container-holder {
    max-width: 900px;
    margin: 2em auto;
  }

  .featured-container {
    background: var(--pale-pink);
    width: 500px;
    padding: 1em 0;
  }

  .featured-video {
    width: 400px;
    height: 225px;
  }
}

/* ========== ABOUT ========== */

.about {
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  margin: 0 2em;
}

.about-me h2 {
  margin-bottom: 0.5em;
}

.about-img-container {
  margin: 1em auto;
  position: relative;
  width: 300px;
  height: 300px;
  flex-grow: 0;
  flex-basis: 0;
  order: 1;
}

.about-img{
  width: 300px;
  height: 300px;
  border: 10px solid white;
  z-index: 2;
  position: relative;
  object-fit: cover;
  object-position: center top;
}

.about-img-container:after{
  content: "";
  width: 300px;
  height: 300px;
  z-index: 1;
  background-color: var(--yellow);
  display: block;
  position: absolute;
  top: 10px;
  transform: rotate(7deg);
}

@media only screen and (min-width: 768px) {
 .about {
   max-width: 700px;
   margin: 0 auto;
  }
 }

@media only screen and (min-width: 992px) {
  .about {
    flex-direction: row;
    gap: 3em;
    justify-items: start;
    align-items: center;
    max-width: 900px;
    margin: 0 auto;
  }

  .about-img-container {
    order: -1;
  }
}

/* ========== CONTACT ========== */

.contact-section {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  margin: 0 2em;
}

.contact-copy {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.contact-copy p {
  width: 90%;
}

.contact-social {
  font-size: 4rem;
  display: flex;
  justify-content: space-evenly;
}

.contact-social a {
  color: var(--dark-pink);
}
.contact-social a:hover,
a:focus {
  color: var(--medium-pink);
}

.contact-form {
  width: 100%;
  margin: 2em;
}

form#mainContactForm {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
  font-family: "oswald";
}

input,
textarea {
  padding: 1em 2em;
  border: none;
}

.form-btn {
  margin: 0 auto;
  width: 150px;
  color: white;
  font-family: Oswald;
  text-transform: uppercase;
  font-size: 1.125rem;
  background-color: var(--dark-pink);
  border: none;
  padding: 0.75em 2.5em;
  cursor: pointer;
}

.form-btn:hover,
.form-btn:focus {
  background-color: var(--medium-pink);
  color: var(--black);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media only screen and (min-width: 768px) {
  .contact-section {
    flex-direction: row;
    align-items: flex-end;
    margin: 0 auto;
    max-width: 700px;
  }

  .contact-form {
    flex-grow: 1;
    flex-basis: 0;
    }
  .contact-copy p {
    width: 85%;
  }
}
@media only screen and (min-width: 992px) {
  .contact-section {
    max-width: 900px;
    margin: 0 auto;
   }
  }

/* ========== CASE STUDY ========== */

li {
  font-family: "DM Sans", sans-serif;
}

.case-study {
  max-width: 900px;
  margin: 0 auto;
}


.case-study_container {
  margin: 2em 2em;
}
.case-study_header {
  display: block;
  text-align: center;
}

.case-study_emphasis {
  font-style: italic;
  font-size: 1.2rem;
  margin: 1em;  
}

.case-study h3, h4, ul, p {
  margin-bottom: 1em;
}

.case-study ul {
  margin-left: 24px;
}

.case-study_intro {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.case-study_links {
  display: flex;
  justify-content: flex-start;
  gap: 1em;
  font-size: 1.5rem;
}

.case-study_links a:hover {
  color: var(--dark-pink);
}

.case-study_a {
  font-family: "DM Sans", sans-serif;
  text-transform: none;
}

.case-study_btn {
display: block;
  margin: 2em auto;
}

.case-study_video {
  width: 300px;
  height: 169px;
  display: block;
  margin: 2em auto;
}

.case-study_imgs {
  width: 80%;
  display: block;
  margin: 2em auto;
}

@media only screen and (min-width: 500px) {
  .case-study_video {
   width: 400px;
   height: 225px;
 }
}

@media only screen and (min-width: 768px) {
  .case-study_video {
    width: 560px; 
    height: 315px;
    display: block;
    margin: 2em auto;
  }

  .case-study_intro {
    flex-direction: row;
    gap: 10em;
  }

  .case-study_links {
    justify-content: space-evenly;
  }

}

@media only screen and (min-width: 992px) {
  .case-study_container {
    margin: 2em 0;
  }
}

.styles_scroll-to-top__2A70v {
  box-shadow: 0 9px 25px 0 rgb(	228, 98, 107, 0.2) !important;
  z-index: 3 !important;
}


/* ========== LINK PAGE ========== */

.linkpage-section {
  max-width: 300px;
  margin: 1em auto;
  text-align: center;
}

.linkpage-section a, .linkpage-section a:visited {
  display: block;
  margin: 1em 0;
  padding: 0.5em 1.25em;
  background-color: var(--dark-pink);
  border: 2px solid var(--dark-pink);
  color: white;
}

.linkpage-section a:hover {
  color: var(--black);
  background-color: var(--medium-pink);
  border: 2px solid var(--medium-pink);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.linkpage-image {
  height: 200px;
  width: 190px;
}


/* ========== 404 PAGE ========== */

.error-page-container {
  width: 75%;
  margin: auto;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-page-container h2, p {
  color: var(--black);
}

.error-page-container p {
  font-size: 1.2rem;
  padding-top: 1em;
}

.error-page-container_box {
  background-color: white;
  overflow:hidden; 
  padding: 4em;
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  border-radius: 0.5rem; 
  text-align: center;
}

.error-page-container h1 {
  font-size: 4rem;
  color: var(--dark-pink);
}

.error-page-link {
  display: block;
  margin: 3em auto 0; 
}

@media only screen and (min-width: 992px) {
  .error-page-container h1 {
    font-size: 8rem;
  }
}